.hero-container {
  width: 100%;
  height: 100vh; /* Full viewport height */
  background-color: black;
  color: aquamarine;
  position: relative; /* Position relative to allow absolute positioning of overlay */
  margin-top: -100px;
  overflow: hidden;
}

.hero-video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the container without stretching */
}

.overlay {
  position: absolute; /* Positioned absolutely to cover the video */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25); /* Nearly transparent overlay color */
  display: flex; /* Flexbox to center the logo */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.logo-slogan {
  max-width: 50%; /* Adjust size as needed */
  max-height: 50%; /* Adjust size as needed */
  pointer-events: none; /* Prevent interaction with the image */
}

/* Media query for screens smaller than 950px */
@media (max-width: 950px) {
  

  .hero-video {
    width: 100%;
    object-fit: cover; /* Ensure the video maintains its aspect ratio */
  }

  .logo-slogan {
    max-width: 70%; /* Adjust size for mobile if needed */
  }
}
