.menu-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin:  5rem 0;
    width: 100%;
    margin: 3rem 0px;
    background-color: #ad1b1b;
}
  
  .menu-content {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
  }
  
  .menu-text {
    flex: 1;
    padding: 2rem 5%;
  }
  
  .menu-subtitle {
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    font-family: 'Nerko One', cursive;
        margin-bottom: 10px;
    color: #fff; /* Customize subtitle color */
  }
  
  .menu-title {
    font-size: 36px;
    font-weight: 900;
    text-align: left;
    margin-bottom: 20px;
    color: #fff; /* Customize title color */
  }
  
  .menu-description {
    font-size: 18px;
    margin-bottom: 20px;
    color: #fff; /* Customize text color */
  }
  
  .menu-button {
    width: 100%;
    margin-top: 3rem;
    display: inline-block;
    padding: 10px 0px;
    background-color: #ad1b1b;
    color: white;
    text-decoration: none;
    font-size: 18px;
    text-decoration: underline;
    font-weight: 600;
    line-height: 1.1h;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .menu-button:hover {
    color: #1c77ab;
    }
  
  .menu-image {
    flex: 1;
    align-items: center;
    max-height: 65vh;
overflow: hidden;
   
  }
  
  .menu-image img {
   object-fit: cover;
   width: 100%;
   height: 100%;

  }
  
  @media screen and (max-width: 950px) {
    .menu-content {
      flex-direction: column;
    }

    .menu-text{
        padding: 1rem 5%;

    }
    .menu-button{
        text-align: center;

    }
  }
  