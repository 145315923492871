@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

/* Global styles */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: "Space Grotesk", sans-serif;
    font-weight: 100;
    font-style: normal;
    background-color: #f8f9f5;
text-transform: none;
}

.container-wrapper {
  max-width: 1500px;
  margin: 0 auto;
  width: 90%;
}



/* Specific styles for elements */
h1 {
    font-size: 46px;
    text-align: center;
    margin-bottom: 4.5rem;
    font-weight: 800;
    text-transform: uppercase;
}
h2 {
    font-size: 55px;

}

p {
    font-size: 16px;
    line-height: 24px;
    font-family: "Roboto Mono", monospace;
    font-weight: 700;
    text-align: justify;
}

.mini-ver {
    
    font-size: 20px;
   
}

@media (max-width: 950px){

    .container-wrapper {
        max-width: 1400px;
        margin: 0 auto;
        width: 90%;
      }


    h1 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 2rem;
        text-transform: uppercase;
      
    }
    h2 {
        font-size: 28px;
    }
    
    p {
        font-size: 16px;
        font-weight: 800;
        line-height: 24px;
        margin-bottom: 3rem;
    }
    
    .mini-ver {
        font-size: 20px;
        
    }
}