.testimonials-section {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  margin: 1rem 0;
}


.testimonials-left {
  width: 45%;
  
  position: relative;
  min-height: 400px; /* Ajuste conforme necessário */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}




.quote-icon {
  font-size: 3em;
  color: #8E1D22;
}

.testimonials-left p {
 
  margin-bottom: 30px;
  text-align: justify;
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.author-info {
  display: flex;
  align-items: center;
  margin-top: auto;
}

.author-info img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.author-info h3 {
  font-size: 1em;
  margin: 0;
}

.author-info p {
  font-size: 0.8em;
  color: #393838;
}

.arrows {
  position: relative;
  bottom: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.arrows span {
  cursor: pointer;
  font-size: 1.5em;
  color: #8E1D22;
}

.testimonials-right {
  width: 45%;
  position: relative;
}

.testimonials-right img {
  width: 100%;
  height: 40vh;
  object-fit: cover;
  border-radius: 8px;
}

.dish-card {
  position: absolute;
  bottom: -20px;
  left: 10%;
  background: #fff;
  padding: 20px;
  border: 1px solid #000;
  
  width: 80%;
  min-height: 200px; /* Ajuste conforme necessário */
}

.dish-card-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.dish-card-header h3 {
  font-size: 1.2em;
  margin: 0;
}

.dish-card-header .rating {
  display: flex;
  align-items: center;
  font-size: 1.2em;
  color: #FFD700;
}

.dish-card-header span {
  font-size: 1em;
  color: #8E1D22;
  margin-left: 10px;
}

.dish-card p {
  font-size: 0.9em;
  color: #000000;
  text-align: justify;
  margin: 10px 0 0;
  min-height: 100px; /* Ajuste conforme necessário */
}









@media screen and (max-width: 950px) {
  .testimonials-section {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .testimonials-left,
  .testimonials-right {
    width: 100%;
  }

  .testimonials-left {
    min-height: 450px; /* Ajuste conforme necessário */
  }

  .dish-card {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    top: -35px;.testimonials-section {
  display: flex;
  justify-content: space-between;
  padding: 50px 5%;
  box-sizing: border-box;
}

.testimonials-left {
  width: 45%;
  
  position: relative;
  min-height: 400px; /* Ajuste conforme necessário */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.testimonials-left h2 {
  font-size: 1.2em;
  color: #8E1D22;
  margin-bottom: 10px;
}


.subtitle{
  display: none;
}

.quote-icon {
  font-size: 3em;
  color: #8E1D22;
}

.testimonials-left p {
  font-size: 1em;
  margin-bottom: 30px;
  text-align: justify;
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.author-info {
  display: flex;
  align-items: center;
  margin-top: auto;
}

.author-info img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.author-info h3 {
  font-size: 1em;
  margin: 0;
}

.author-info p {
  font-size: 0.8em;
  color: #777;
}

.arrows {
  position: relative;
  bottom: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.arrows span {
  cursor: pointer;
  font-size: 1.5em;
  color: #8E1D22;
}

.testimonials-right {
  width: 45%;
  position: relative;
}

.testimonials-right img {
  width: 100%;
  border-radius: 8px;
}

.dish-card {
  position: absolute;
  bottom: -20px;
  left: 10%;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  min-height: 200px; /* Ajuste conforme necessário */
}

.dish-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.dish-card-header h3 {
  font-size: 1.2em;
  margin: 0;
}

.dish-card-header .rating {
  display: flex;
  align-items: center;
  font-size: 1.2em;
  color: #FFD700;
}

.dish-card-header span {
  font-size: 1em;
  color: #8E1D22;
  margin-left: 10px;
}

.dish-card p {
  font-size: 0.9em;
  color: #777;
  text-align: justify;
  margin: 10px 0 0;
  min-height: 100px; /* Ajuste conforme necessário */
}

@media screen and (max-width: 950px) {
  .testimonials-section {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .testimonials-left,
  .testimonials-right {
    width: 100%;
  }

  .testimonials-left {
    min-height: 450px; /* Ajuste conforme necessário */
  }

  .dish-card {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    top: -35px;
  }
}
  }
}