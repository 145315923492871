.about-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 0%;
  box-sizing: border-box;
  flex-wrap: wrap;
  margin-top: 5rem;
  margin-bottom: 15%;
}

.about-left-column {
  position: relative;
  width: 50%;
  align-self: start;
}

.food-image {
  width: 65%;
  border-radius: 6px;
}

.top-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.bottom-image {
  position: absolute;
  top: 6rem;
  right: 0px;
  z-index: 3;
}

.veg-image {
  position: absolute;
  width: 40%;
  top: 0;
  right: 0px;
  z-index: 2;
}

.about-right-column {
  width: 45%;
}



@media screen and (max-width: 950px) {
  .about-section {
      flex-direction: column-reverse;
      align-items: center;
      text-align: center;
      margin-bottom: 0rem;

  }

  .about-left-column {
      width: 100%;
      height: auto;
      position: relative;
      align-self: center;
  }

  .about-right-column {
      width: 100%;
      height: auto;
      position: relative;
      align-self: center;
      margin-bottom: 5rem;
  }

  .food-image {
      position: absolute;
      max-height: 100%;
  }

  .cards {
      justify-content: center;
      order: 1; /* This will make the cards section appear first */
  }

  .about-text {
      order: 2; /* This will make the text section appear second */
  }

  .card {
      width: calc(50% - 10px); /* Mantém os cards lado a lado em telas menores */
  }

  .cards .card:nth-child(odd) {
      margin-right: 0;
  }
}
