/* navbar.css */
.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;
  padding: 20px 5%; /* Adiciona 5% de espaçamento nas laterais */
  box-sizing: border-box; /* Inclui o padding no cálculo da largura total */
  background: none; /* Transparent background */
  position: relative;
  z-index: 1;
  width: 100%;
  background-color: transparent;
}


  
  .navbar-brand {
    font-size: 30px;
    text-decoration: none;
    font-weight: bold;
    font-family: "Vast Shadow", serif;

    color: rgb(255, 255, 255); /* Adjust as needed */
  }
  
  .navbar-nav {
    list-style: none;
    display: flex;
    gap: 20px; /* Adjust spacing between menu items */
    margin: 0;
    padding: 0;
  }
  
  .nav-item {
    font-size: 1em;
  }
  
  .nav-link {

    text-decoration: none;
    color: white; /* Adjust as needed */
    position: relative;
  }
  
  .nav-link:hover {
    color: #e5300c; /* Adjust hover color as needed */
  }
  
  .nav-link::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #343031; /* Adjust underline color as needed */
    transition: width 0.3s;
  }
  
  .nav-link:hover::after {
    width: 100%;
  }
  
  .dropdown {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.922);
    border: 1px solid #ddd;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .dropdown-item {
    padding: 10px;
  }
  
  .dropdown-item .nav-link {
    color: black;
  }
  
  .menu-toggle {
    display: none;
    cursor: pointer;
  }
  
  .hamburger {
    width: 25px;
    height: 2px;
    background-color: black;
    position: relative;
  }
  
  .hamburger::before,
  .hamburger::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    transition: all 0.3s;
  }
  
  .hamburger::before {
    top: -8px;
  }
  
  .hamburger::after {
    bottom: -8px;
  }
  
  @media screen and (max-width: 950px) {
    .navbar-nav {
      display: none;
    }
  
    .menu-toggle {
      display: block;
    }
  
    .navbar-brand {
    font-size: 18px;
    }

    .mobile-menu {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.9);
      
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 100;
      animation: fadeIn 0.3s;
    }
  
    .mobile-nav {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-content: center;
      gap: 20px;
      text-align: center; /* Center the text */
    }
  
    .mobile-dropdown {
      list-style: none;
      margin: 10px 0 0 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  
    .close-btn {
      position: absolute;
      top: 30px;
      right: 5%;
      background: none;
      border: none;
      font-size: 1.5em;
      cursor: pointer;
    }
  
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
  