.gallery-container {
    max-height: 75vh;
    overflow: hidden;
    margin-top: 4rem;
    margin-bottom: 6rem;
  }
  
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
  }
  
  @media (min-width: 768px) {
    .gallery-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  .gallery-column {
    display: grid;
    gap: 1rem;
  }
  
  .gallery-image {
    height: auto;
    max-width: 100%;
    object-fit: cover;
  }
  
  
  
  @media screen and (max-width: 950px) {
  
  .gallery-container{
    height: 70vh;
    margin-top: 1rem;

  }
  
  }
  
  
  
  
  
  
  /*    Column Gallery  */
  
  .column-gallery-container {
    height: 60vh;
    overflow: hidden;
    width: 100%;
  }
  
  
  .column-gallery-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  
  
  .column-gallery {
    display: grid;
    gap: 1rem;
  }
  
  
  
  .column-gallery-image {
    height: auto;
    object-fit: cover;
  }
  
  @media screen and (max-width: 950px) {
    .column-gallery-container {
      height: 70vh;
      margin-bottom: 3rem;
      display: flex;
      align-items: start;
      align-content: start;
    }
  }
  